import React, { useEffect, useState } from 'react';
import Greeting from '../common/Greeting'
import Page6Cards from '../layouts/Page6Cards';
import SearchInput from '../inputs/SearchInput';
import { Table, Button, Tooltip } from 'antd';
import { BsFillPersonFill } from 'react-icons/bs';
import { AiOutlineFileDone } from 'react-icons/ai';
import { BiBarChart } from 'react-icons/bi';
import { RiMailSendLine } from 'react-icons/ri'
import { Modal } from 'antd';
import EmployeeDetails from './EmployeeDetails';
import CardDetails from '../layouts/CardDetails';
import PersonalTarget from '../layouts/PersonalTarget';
import Message from '../layouts/Message';
import Performance from '../layouts/Performance';
import { Get } from '../../utility/fetch'
import { URLAPI } from '../../utility/ApiMethods';
import { RiArrowDownSFill } from 'react-icons/ri'
import ReactSelect from 'react-select';



const Dashboard = ({ history }) => {



  const [mda, setMda] = useState(null);
  const [mdaStats, setMdaStats] = useState([])
  const [showSearch, setShowSearch] = useState(false)
  const [dropDownOptions, setDropDownOptions] = useState(false)
  const [totalMdas, setTotalMdas] = useState(0);
  const [totalStaff, setTotalStaff] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [employeeRecords, setEmployeeRecords] = useState({})
  const [performance, setPerformance] = useState([]);
  const [staffAppraisal, setStaffAppraisal] = useState([]);
  const [agencyStaffStrength, setAgencystaffStrength] = useState(0)
  const [filterButtonText, setFilterButtonText] = useState("Select MDA")
  const [isModalVisible0, setIsModalVisible0] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const [table, setTables] = useState([])
  const [reset, setReset] = useState(false)





  
  
  function capitalizeFirstLetter(string) 
{
  return string && string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}





  const [title, setTitle] = useState('');
  var data = [];



  

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Dashboard';
    setTitle('Dashboard');
  }, []);

  useEffect(() => {
    Get(URLAPI.Dashboard.Stats, setTotalDetails)
    Get(URLAPI.Dashboard.MdaList, populateDropdown)
  }, [])

  


  const numberWithCommas =(x)=> {
    console.log(x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g,","))
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g,",");
}
  



  const setTotalDetails = (data) => {
    if (Array.isArray(data.result)) {
      setTotalMdas(data?.result[0]?.agencyCount);
      setTotalStaff(data?.result[0]?.civilServants);
    }

  }

  const populateDropdown = (data) => {
    if(Array.isArray(data.result)){
      setDropDownOptions(
        data.result.map((item) => (
          {
            key: item.id,
            value: item.id,
            label: item.name
          }
        ))
      )
    }
  }



  const handleSelected = (selected) => {
    setMda(selected.label)
    setFilterButtonText(selected.label)
    Get(URLAPI.Dashboard.MdaStaff, updateStaffList, selected.value)
    Get(URLAPI.Dashboard.MdaStats,updateStats, selected.value)
  }


  const updateStaffList = (data) => {
    toggleSearchButton();

    console.log(data)
    setEmployees(data.result)
    Array.isArray(data.result) ? setAgencystaffStrength(data?.result.length) : setAgencystaffStrength(0);
  }


  const updateStats =(data)=>{
    Array.isArray(data.result) && data.result.length !==0 ? setMdaStats(data.result[0]) : setMdaStats([])
  }


  const toggleSearchButton = () => {
    setShowSearch(!showSearch);
    setTables([]);
    setMdaStats([])
  }

  const showModal = (num) => {

    if (num === 1){
      setIsModalVisible1(true);
    }
    else if (num === 2)
      setIsModalVisible2(true);
    else if (num === 3)
      setIsModalVisible3(true);
    else {
      setIsModalVisible0(true);
    }

  };

  const handleEmployee =(record)=>{
    setEmployeeRecords(record)
    showModal(0)
    // setReset(false)
  }

  const handlePersonal = (record) =>{
    console.log(record.id)
    setEmployeeRecords(record)
    Get(URLAPI.Dashboard.Personal, handlePersonalData, record.id)
  }

  const handlePersonalData =(data)=>{
    console.log(data)
    setStaffAppraisal(data.result);
    showModal(1);
  }

  const handlePerformance = (record) =>{
    setEmployeeRecords(record)
    Get(URLAPI.Dashboard.DetailedAppraisal, handlePerformanceData, record.id)
  }

  const handlePerformanceData =(data)=>{
    setPerformance(data.result);
    showModal(2)
  }


  const handleCancel = () => {
    setReset(true);
    setIsModalVisible0(false);
    setIsModalVisible1(false);
    setIsModalVisible2(false);
    setIsModalVisible3(false);
  };
  const columns = [

    {
      title: 'Staff Number #',
      dataIndex: 'staffNumber',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'First Name',
      dataIndex: 'firstname',
      // width: '55%',
      editable: false,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastname',
      // width: '55%',
      editable: false,
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      width: '30%',

    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      width: '10%',

    },
    {
      title: 'Step',
      dataIndex: 'step',
      width: '10%',

    },
    {
      title: 'Salary',
      dataIndex: 'salary',
      // width: '25%',

    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {

        return (
          <div className="flex space-between w-100">
            <Tooltip title="Personal Profile">
              <div
                className='pointer'
                onClick={() => { handleEmployee(record) }}
                style={{ backgroundColor: "#1C811C45", display: "flex", alignItems: "center", marginRight: "10px", }}
              >
                <BsFillPersonFill color="#1C811C" style={{ fontSize: "28px", padding: "5px" }} />
              </div>
            </Tooltip>

            <Tooltip title='Personal Target'>
              <div
                className='pointer'
                onClick={()=>handlePersonal(record)}
                style={{ backgroundColor: "#1C811C45", display: "flex", alignItems: "center", marginRight: "10px", }}
              >
                <AiOutlineFileDone color="#1C811C" style={{ fontSize: "28px", padding: "5px" }} />
              </div>
            </Tooltip>

            <Tooltip title='Performance'>
              <div
                className='pointer'
                onClick={() => { handlePerformance(record) }}
                style={{ backgroundColor: "#1C811C45", display: "flex", alignItems: "center", marginRight: "10px", }}
              >
                <BiBarChart color="#1C811C" style={{ fontSize: "28px", padding: "5px" }} />
              </div>
            </Tooltip>

            <Tooltip title='Send Message'>
              <div
                className='pointer'
                onClick={() => { showModal(3) }}
                style={{ backgroundColor: "#1C811C45", display: "flex", alignItems: "center", marginRight: "10px", }}
              >
                <RiMailSendLine color="#1C811C" style={{ fontSize: "28px", padding: "5px" }} />
              </div>
            </Tooltip>


          </div>)
      }


    },
  ];



  useEffect(()=>{
   setTables(
     Array.isArray(employees) && employees.map((employee) => (
      {
        key:employee.id,
        staffNumber: employee.staffID,
        firstname: employee.firstName,
        lastname: employee.lastName,
        designation: employee.jobTitle,
        grade: employee.currentLevel,
        step: employee.currentStep,
        salary: `₦${numberWithCommas(employee?.salary)}`,
        cadre: employee.currentCadre,
        dateOfConfirmation: employee.dateOfConfirmation,
        dateOfFirstApp: employee.dateOfFirstApp,
        dateOfPresentPosition: employee.dateOfPresentPosition,
        firstAppCadre: employee.firstAppCadre,
        gender: employee.gender,
        id: employee.id,
        mdaId: employee.mdaId,
        mdaName: employee.mdaName,
        profilePicture: employee.profilePicture,
        title: employee.title
  
      }
    ))
   ) 
  }, [employees])

  
useEffect(()=>{
  console.log(data)
}, [employees])


  return (
    <div className='w-100'>
      <div className='m-b-40'>
        <Greeting />
      </div>

      <div className="flex space-between w-100 m-b-50">
        <Page6Cards cardTitle='MDAs' count={totalMdas} />
        <Page6Cards cardTitle='Number of Civil Servants' count={totalStaff} />
        <Page6Cards cardTitle="MDA's Staff Strength" count={mdaStats.staffStrength} />
        <Page6Cards cardTitle="MDA's Directors" count={mdaStats.directorCount}/>
        <Page6Cards cardTitle="Total Monthly Salary" count={`₦ ${mdaStats.monthlySalary ? numberWithCommas(mdaStats.monthlySalary) : 0}`}/>
      </div>

      <div className='flex flex-direction-column w-100'>
        <div className='flex space-between ' style={{width:'70%',marginLeft:'auto', }}>
         
          <div className='flex w-100' >
            {
              showSearch ? <ReactSelect autoFocus className='w-50 m-r-10'  options={dropDownOptions} onChange={handleSelected} /> :
              //   <button className='btn btn-large w-50 space-between flex flex-v-center flex-h-center m-b-20 m-r-10 w-40 ' onClick={toggleSearchButton}>
              //     <span>{filterButtonText} </span> <RiArrowDownSFill style={{ color: 'white', fontSize: "30px", paddingBottom: '5px' }} />
              //   </button>

                  <Button block className='flex w-50 m-r-10' style={{backgroundColor:'#1C811C', color:'white',padding:'21px', justifyContent:'space-between',alignItems:'center'}}  onClick={toggleSearchButton}>
                  <p style={{marginBottom:'0'}}>{filterButtonText} </p> <RiArrowDownSFill style={{ color: 'white', fontSize: "30px", paddingBottom: '5px', marginLeft:'5px' }} />
                  </Button>
            }
            <SearchInput className='m-b-20' />
          </div>

        </div>

        <div className='flex space-between primary-color flex-v-center p-20' >
          
            <h3>{mda || 'MDA'} </h3>
          
          <Button>
            Target vs Actuals
          </Button>
        </div>

        <div style={{ width: "100%", }}>


          <Table
            bordered
            dataSource={table}
            columns={columns}
            rowClassName="editable-row"
          />
        </div>
      </div>


      <Modal footer={null} visible={isModalVisible0} onCancel={handleCancel} width={1200}>
        <CardDetails department={mda} cardTitle = {`${capitalizeFirstLetter(employeeRecords.firstname)} ${capitalizeFirstLetter(employeeRecords.lastname)}'s Personal Profile`}/>
        <EmployeeDetails reset = {reset} employeeRecord={employeeRecords} />
      </Modal>




      <Modal footer={null} visible={isModalVisible1} onCancel={handleCancel} width={1200}>
        <PersonalTarget personalTarget = {staffAppraisal} cardTitle = {`${employeeRecords.firstname} ${employeeRecords.lastname}'s Personal Profile`}/>
      </Modal>

      <Modal footer={null} visible={isModalVisible2} onCancel={handleCancel} width={1200}>
        <CardDetails department={mda} cardTitle = {"Performance"}/>
        <Performance performance={performance} />
      </Modal>

      <Modal footer={null} visible={isModalVisible3} onCancel={handleCancel} width={1200}>
        <Message />
      </Modal>

    </div>
  );
};

export default Dashboard;
