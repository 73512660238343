/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import Cookies from "js-cookie";
import { createBrowserHistory } from "history";

// const URL = "http://18.225.15.178/leave";
// password = DuVZKH%_:qyr=+q1
const URL = process.env.REACT_APP_BACKEND_URL_STAFF;
// const URL = "http://devsvr.edogoverp.com/leave";
const history = createBrowserHistory();
export let Access_token = Cookies.get("token");

console.log("logging Token in the fetch file", Access_token);

export const NewLogin = async (method, data, callbackfunction) => {
  const { urltoken } = data;
  const response = axios.post(`${URL}${method}?urltoken=${urltoken}`, {
    // const response = axios.post(`${URL}/api/Auth/signin?id=3`, {
    headers: {
      "Access-Control-Allow-Credentials": true,
      crossorigin: true,
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Origin": "*",
    },
  });
  await response
    .then((response) => {
      let result = response.data;
      callbackfunction(result);
    })
    .catch((error) => {
      callbackfunction(error);
    });
};

export const GetwithQueryString = async (method, data, callbackfunction) => {
  const { name, value } = data;

  const response = axios.get(`${URL}${method}?${name}=${value}`, {
    headers: {
      Authorization: `Bearer ${Access_token}`,
      "Access-Control-Allow-Credentials": true,
      crossorigin: true,
      "Access-Control-Allow-Methods": "GET",
      "Access-Control-Allow-Origin": "*",
    },
  });

  await response
    .then((response) => {
      let result = response.data;
      callbackfunction(result);
    })
    .catch((error) => {
      callbackfunction(error);
    });
};

export const PostWithNoToken = async (data, method, callbackfunction) => {
  const response = axios.post(URL + method, data, {
    headers: {
      "Access-Control-Allow-Credentials": true,
      crossorigin: true,
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Origin": "*",
    },
  });
  await response
    .then((response) => {
      let result = response.data;
      callbackfunction(result);
    })
    .catch((err) => {
      callbackfunction(err);
    });
};

export const GetWithoutToken = async (data, method, callbackfunction) => {
  const response = axios.get(URL + method, data);
  await response
    .then((response) => {
      let result = response.data;
      callbackfunction(result);
    })
    .catch((err) => {
      callbackfunction(err);
    });
};

export const GetWithData = async (method, data, callbackfunction) => {
  const response = axios.get(URL + method, data, {
    headers: {
      Authorization: `Bearer ${Access_token}`,
    },
  });
  await response
    .then((response) => {
      let result = response.data;
      callbackfunction(result);
    })
    .catch((err) => {
      callbackfunction(err);
    });
};

export const GetWithoutData = async (method, callbackfunction) => {
  const response = axios.get(URL + method, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Credentials": true,
      crossorigin: true,
      "Access-Control-Allow-Methods": "GET",
      "content-type": "application/json",
    },
  });
  await response
    .then((response) => {
      let result = response.data;
      callbackfunction(result);
    })
    .catch((err) => {
      callbackfunction(err);
    });
};

export const Post = async (data, method, callbackfunction) => {
  Access_token = Cookies.get("token");
  const response = axios.post(URL + method, data, {
    headers: {
      Authorization: `Bearer ${Access_token}`,
      "Access-Control-Allow-Credentials": true,
      crossorigin: true,
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Origin": "*",
    },
  });
  await response
    .then((response) => {
      let result = response.data;
      callbackfunction(result);
    })
    .catch((error) => {
      callbackfunction(error);
    });
};


export const Posts = async (data, method, callbackfunction, tokened) => {
  Access_token = tokened;
  const response = axios.post(`https://www.edogoverp.com/newschedule/api/${method}`, data, {
    headers: {
      Authorization: `Bearer ${Access_token}`,
      "Access-Control-Allow-Credentials": true,
      'Content-Type': 'application/json',
      crossorigin: true,
      'X-API-KEY': "e8q05322dhg429b4f778a4pa97ry62eb37q",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Origin": "*",
    },
  });
  await response
    .then((response) => {
      let result = response.data;
      callbackfunction(result);
    })
    .catch((error) => {
      callbackfunction(error);
    });
};

//remove authori
export const FileUpload = async (data, callbackfunction) => {
  Access_token = Cookies.get("token");
  const response = axios.post(
    "https://edogoverp.com/services/api/documents/staffreview",
    data,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Credentials": true,
        crossorigin: true,
        "Access-Control-Allow-Methods": "POST",
      },
    }
  );
  await response
    .then((response) => {
      let result = response.data;
      callbackfunction(result);
    })
    .catch((error) => {
      callbackfunction(error);
    });
};

export const searchDocument = (userId, title) => {
  const options = {
    url: `https://edogoverp.com/services/api/dashboard/searchdocument?user_id=${userId }&DocTitle=${title}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      crossorigin: true,
      'Access-Control-Allow-Methods': 'GET',
      'Access-Control-Allow-Origin': '*',
    },
  };
   console.log('options',options);
  return axios(options).then(
    Cookies.set('userId',userId),
    (res) => res,
    (err) => err.response,
  );
};

export const PostWithoutData = async (method, callbackfunction) => {
  const response = axios.post(URL + method);
  console.log("Logging response", response);
  await response
    .then((response) => {
      let result = response.data;
      callbackfunction(result);
    })
    .catch((error) => {
      callbackfunction(error);
    });
};

export const Get = async (method, callbackfunction, param) => {
  console.log(method)
  console.log(param)
  let urls = ``
  if(param){
    urls += `${URL}${method}/${param}`
  }
  else
   urls = `${URL}${method}`

  Access_token = Cookies.get("token");
  const response = axios.get(`${urls}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${Access_token}`,
      withCredentials: true,
      "Access-Control-Allow-Credentials": true,
      crossorigin: true,
      "Access-Control-Allow-Methods": "GET",
      "content-type": "application/json",
    },
  });
  await response
    .then((response) => {
      let result = response.data;
      callbackfunction(result);
    })
    .catch((error) => {
      callbackfunction(error);
    });
};



export const Gets = async (method, callbackfunction, param, tokened) => {
  console.log(method)
  console.log(param)
  console.log(tokened)
  let urls = ``
  if(param){
    urls += `https://www.edogoverp.com/newschedule/api/${param}`
  }
  else
   urls = `${URL}${method}`

  Access_token = tokened;
  const response = axios.get(`${urls}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${tokened}`,
      withCredentials: true,
      'X-API-KEY': "e8q05322dhg429b4f778a4pa97ry62eb37q",
      "Access-Control-Allow-Credentials": true,
      crossorigin: true,
      "Access-Control-Allow-Methods": "GET",
      "content-type": "application/json",
    },
  });
  await response
    .then((response) => {
      let result = response.data;
      callbackfunction(result);
    })
    .catch((error) => {
      callbackfunction(error);
    });
};




export const Put = async (data, method, callbackfunction) => {
  console.log(method)
  const urls = `${URL}${method}`
  Access_token = Cookies.get("token");
  const response = axios.put(`${urls}`, data, {
    headers: { Authorization: `Bearer ${Access_token}` },
  });
  await response
    .then((response) => {
      let result = response.data;
      callbackfunction(result);
    })
    .catch((error) => {
      callbackfunction(error);
    });
};

export const Delete = async (method, callbackfunction) => {
  Access_token = Cookies.get("token");
  const response = axios.delete(URL + method, {
    headers: { Authorization: `Bearer ${Access_token}` },
  });
  await response
    .then((response) => {
      let result = response.data;
      callbackfunction(result);
    })
    .catch((error) => {
      callbackfunction(error);
    });
};

export const logout = () => {
  Cookies.remove("token");
  Cookies.remove("tokenExist");
  Cookies.remove("fullname");
  window.location.replace("/home");
  history.push("/home");
};

export default {
  Post,
  Get,
  Put,
  Delete,
  URL,
  PostWithoutData,
  PostWithNoToken,
  Access_token,
  GetWithoutToken,
  GetwithQueryString,
  GetWithData,
  FileUpload,
  logout,
};
