/* eslint-disable */
import React, { Component } from "react";
import { Store } from "react-notifications-component";
import queryString from "query-string";
import Cookies from "js-cookie";
import { post as postExp } from '../../utility/fetchExpense'
import { post as postBud } from '../../utility/fetchBudget';
import axios from "axios";
import moment from "moment";
import LoadingSpinner from "../../resources/loadingSpinner";
import notification from "../../utility/notification";
import greenzone from "../../assets/images/download.png";
import logo from "../../assets/images/Group-2.png";

class Connect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: null,
      result: "",
      loading: false
    };
  }


  getExpenseToken = async() => {
    console.log("here")
    try {
      const emp = new URLSearchParams(this?.props.location.search).get('emp');
      const base = new URLSearchParams(this?.props.location.search).get('base');
      const res = await postExp(
        `/api/Auth/authorization?urltoken=${emp}`
      );
      console.log(res)
      if (res.code === 1) {
        sessionStorage.setItem('tokenExp', res.token);
        localStorage?.setItem("mda", res?.mda);
        await this?.getBudgetToken()
       
        // props.history.push('/treasuryops');
      }
    } catch (error) {
      localStorage.removeItem('USER_INFO');
      console.log(error)
      
    }
  }

  
   getBudgetToken = async() =>{
    try {
      const emp = new URLSearchParams(this.props.location.search).get('emp');
      const base = new URLSearchParams(this.props.location.search).get('base');
      const res = await postBud(
        `/Auth/authorization?urltoken=${emp}`
      );
      console.log(res)
      if (res.statusCode === "00") {
        sessionStorage.setItem('budgetToken', res?.result?.token);
        // localStorage?.setItem("mda", res?.mda)
        // props.history.push('/treasuryops');
      }
    } catch (error) {
      localStorage.removeItem('USER_INFO');
      console.log(error);
      notification({
        title: 'ACCESS DENIED',
        message: 'Sorry, This user does not have access to this application. Please Contact Admin',
        type: 'warning',
      });
    }
  }

  checkurl = async () => {
    const parsed = queryString.parse(this.props.location.search);

    const { history } = this.props;

    //this change with respect to each module
    //Edogov_(enter module name);
    const ModuleName = "Edogov_Staff_Review";

    //check if there is a jwt token saved before
    const AccessToken = Cookies.get(ModuleName + "_AccessToken");

    //check if there is an app token saved before
    const AppToken = Cookies.get("token");

    //get the base url from query string(edogoverp.com)
    const baseurl = parsed.base;



    // get the help Link
    Cookies.set("helpLink", parsed.help);
    Cookies.set("emp", parsed.emp);

    if (parsed.emp !== "") {
      this.setState({
        loading: true
      });





      const authurl = "/staffreview/api/Auth/authorization"; //change this to the module authenthication endpoint

      try {

        const res = await axios.post(
          `${baseurl}/treasuryrevamped/api/Authentication/AccessToken?Token=${parsed.emp}`
        );
        console.log(res)
        if (res.status === 200) {
          console.log(res.data.data)
          await this?.getExpenseToken();
          localStorage.setItem('token', res.data.data.token);
          localStorage.setItem("baseURL", base, { expires: 0.0416665 })
          localStorage.setItem("homeLink", data.homeLink, { expires: 0.0416665 })
          localStorage.setItem('USER_INFO', JSON.stringify(data));


        }
      } catch (error) {
        localStorage.removeItem('USER_INFO');

      }

      axios
        .post(baseurl + authurl + "?urltoken=" + parsed.emp, {
          headers: {
            "Access-Control-Allow-Credentials": true,
            crossorigin: true,
            "Access-Control-Allow-Methods": "POST",
            "Access-Control-Allow-Origin": "*"
          }
        })

        .then(async (user_auth) => {
          console.log("logging api response", user_auth.data);
          console.log("token to be stored ====", user_auth.data.result.token);
          console.log("id to be stored ====", user_auth.data.result.id);

          Cookies.set(ModuleName + "userId", user_auth.data.result.id);
          Cookies.set("userId", user_auth.data.result.id);
          Cookies.set("role", user_auth.data.result.role);
          Cookies.set("email", user_auth.data.result.email);
          Cookies.set(ModuleName + "_Picture", user_auth.data.result.picture);
          //Cookies.set(ModuleName + "_FirstName", user_auth.result.firstName);
          Cookies.set(
            "fullName",
            `${user_auth.data.result.firstName} ${user_auth.data.result.lastName}`
          );
          Cookies.set("loginTime", moment().format("HH:mm a"));
          // Cookies.set(ModuleName + "_LastName", user_auth.result.lastName);
          Cookies.set("token", user_auth.data.result.token, { expires: 0.0416665 });
          Cookies.set("tokenExist", true, { expires: 0.0416665 });

          Cookies.set("homeLink", user_auth.data.result.homeLink); //for home redirection
          Cookies.set(ModuleName + "_AccessToken", parsed.emp);
          Cookies.set(ModuleName + "_APIBaseURL", parsed.base);
          if (AppToken !== null && AppToken !== "") {
            this.setState({ loading: true });

            const { history } = this.props;
            // console.log('')
            //redirect to dashboard
            // history.push("/staff-review");
            if (parsed.approval === "True") {
              history.push("/staff-review/finance-approval");
            }
            else if (parsed.dashboard === "True") {
              history.push("/staff-review/finance-dashboard");
            }
            else if (parsed.budget === "True") {
              history.push("/staff-review/budget-performance");
            }
            else {
              history.push("/staff-review/");
            }
            
          } else {
            Store.addNotification({
              title: "Error!",
              message: user_auth.message,
              type: "danger",
              insert: "bottom",
              container: "bottom-left",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            });
          }
        })

        .catch((error) => {
          console.log(error);
          Store.addNotification({
            title: "Error!",
            message: "Authentication Failed",
            type: "danger",
            insert: "bottom",
            container: "bottom-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
          // setTimeout(() => (window.location.href = `${Cookies.get('homeLink')}`), 3000);
        });
    }

    console.log("logging baseurl and Emp", parsed.url, parsed.emp);
  };




  componentDidMount() {
    // location();
    this.checkurl();
    

  }

  render() {
    const { loading } = this.state;
    return (
      <div className="w-100 flex flex-v-center flex-h-center" style={{ minHeight: "105vh" }}>
        {loading ? <LoadingSpinner /> : ""}
      </div>
    );
  }
}

export default Connect;
