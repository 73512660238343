import React from "react";
import EdsgLogo from "../assets/images/edsg-logo-250.png";
import logo from "../assets/images/Group-2.png";
import greenzone from "../assets/images/download.png";

export const LoadingSpinner = (props) => (
  <div className="loading-overlay">
    <div className="loading-inner-box">
      {/* <img src={EdsgLogo} alt="Edo State Logo" /> */}
      <img src={logo} alt="logo" className="brand" width="60" />
                <div>
                  <img src={greenzone} alt="logo" className="brand" width="100" />
                  <h1 style={{ color: "green", fontWeight: "bolder" }}>GREENGOV</h1>
                </div>
      {props.message ? `${props.message}` : "Loading..."}
    </div>
  </div>
);

export default LoadingSpinner;
