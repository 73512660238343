import React, { useEffect, useState, } from "react";
import "../../assets/css/Dashboard.css";
import { get } from "../../utility/fetchBudget";
import Greeting from "../common/Greeting";
import LocationDetails from "../layouts/LocationDetails";
import notification from "../../utility/notification";
import "../../assets/css/calendar.css";
import { Button, Pagination, Select } from "antd";
import ReportTableAll from "../layouts/ReportTableAll";
import AdminBudgetReqForReports from "./AdminBudgetReqForReports";

const { Option } = Select;

const Dashboard = (prop) => {
  const { history } = prop;

  const fullName = "";
  const role = "";
  const time = "";

  const pdfExportComponent = React.useRef(null)
  const [employee, setEmployee] = useState([]);
  const [subject, setSubject] = useState("");
  const [budgetPeriodId, setBudgetPeriodId] = useState();
  const [OverallBudgetVsActual, setOverallBudgetVsActual] = useState();
  const [OverallBudgetVsActualByMda, setOverallBudgetVsActualByMda] =
    useState();
  const [envelope, setEnvelope] = useState([]);
  const [hideMdaSelect, sethideMdaSelect] = useState(false);
  const [budgetButtonType, setbudgetButtonType] = useState("overhead");
  const [isDetailed, setisDetailed] = useState("first");
  const [SelectedMdaId, setSelectedMdaId] = useState();
  const [viewMdaId, setviewMdaId] = useState(false);

  const [coloredBg, setcoloredBg] = useState("first");
  const [acceptedBudgetEnvelopeArray, setAcceptedBudgetEnvelopeArray] =
    useState([]);
  const [listOfMdas, setListOfMdas] = useState([]);
  const [mdaId, setMdaId] = useState(undefined);
  const [data, setdata] = useState();
  const [dataDetail, setdataDetail] = useState([]);
  const [dataDetailOriginal, setdataDetailOriginal] = useState([]);
  const [dataDetailMda, setdataDetailMda] = useState([]);
  const [dataDetailMdaAll, setdataDetailMdaAll] = useState([]);

  const [showDataSetail, setshowDataSetail] = useState(false);
  const [pdfName, setPdfName] = useState("");
  const [budgetPeriod, setBudgetPeriod] = useState([]);

  useEffect(() => {
    // onReset()
    getBudgetPeriod();
    setPdfName(subject);
    getEmployeeList();
    getMdas();
    // let ui = localStorage.getItem("ui");
    // ui = decodeToken("ui").ui;
    // console.log(ui);
    // setuserId(ui);
  }, [subject]);

  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getBudgetPeriod = async () => {
    // axios
    //   .get("https://edogoverp.com/newbudgetapi/Settings")

    const res = await get("/Settings");
    console.log(res)
    if (res) {
      console.log(res.find((e) => e.isCurrent).id);
      setBudgetPeriodId(res.find((e) => e.isCurrent).id);
      setBudgetPeriod(res);
      // console.log(res.find((e) => e.current)?.id);

      // this.setState({
      //     allBudgetCodification: response,
      //     loader: false,
      // });
    } else {
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response,
      //     loader: false,
      // });
    }
  };

  const getMdas = async () => {
    // axios
    //   .get(`${BASE_API_URLs}v1/Mda`)

    const res = await get(
    `/v1/mda`,
      // auth: true,
      // body: payload,
    );
    console.log(res)
    if (res) {
      console.log(res);
      setListOfMdas(res);

      // this.setState({
      //     allBudgetCodification: response,
      //     loader: false,
      // });
    } else {
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response,
      //     loader: false,
      // });
    }
  };

  const getBudgetVsActualByAll = async () => {
    setcoloredBg("first");
    // axios
    //   .get(`${BASE_API_URLs}v1/Mda`)
    setviewMdaId(true);
    setshowDataSetail(false);
    setisDetailed("");

    const res = await get(
    `/Reports/GetOverallBudgetVsActual?budgetPeriodId=${budgetPeriodId}`,
      // auth: true,
      // body: payload,
    );
    if (res ) {
      console.log(res);
      setOverallBudgetVsActual(res);
      setdata(res);

      // this.setState({
      //     allBudgetCodification: response,
      //     loader: false,
      // });
    } else {
      setdata({
        totalBudget: 0,
        actualSpent: 0,
        percentageUtilization: 0,
      });
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response,
      //     loader: false,
      // });
    }
  };
  const getBudgetVsActualByMdas = async (id) => {
    setcoloredBg("second");

    // axios
    //   .get(`${BASE_API_URLs}v1/Mda`)
    setisDetailed("first");
    setviewMdaId(true);
    setshowDataSetail(false);
    if (id) {
      const res = await get(
        `/Reports/GetBudgetVsActualByMdaId?id=${id}&budgetPeriodId=${budgetPeriodId}`,
        // auth: true,
        // body: payload,
      );
      if (res ) {
        console.log(res);
        setOverallBudgetVsActualByMda(res);
        setdata(res);

        // this.setState({
        //     allBudgetCodification: response,
        //     loader: false,
        // });
      } else {
        setdata({
          totalBudget: 0,
          actualSpent: 0,
          percentageUtilization: 0,
        });
        // this.setState({
        //     message: nullcheck(err.response) == "" ? "server error" : err.response,
        //     loader: false,
        // });
      }
    } else {
      alert("Select MdaId");
      setdata({
        totalBudget: 0,
        actualSpent: 0,
        percentageUtilization: 0,
      });
    }
  };
  const getOverallAdminDetailsByAll = async (page) => {
    setcoloredBg("third");

    // axios
    //   .get(`${BASE_API_URLs}v1/Mda`)
    setshowDataSetail(true);
    setviewMdaId(false);
    setisDetailed("");

    const res = await get(
    `/Reports/GetOverallAdminReport?budgetPeriodId=${budgetPeriodId}&pageNumber=${page}&pageSize=${20}`,
      // auth: true,
      // body: payload,
    );
    if (res ) {
      console.log(res);
      setdataDetailOriginal(res);
      setdataDetailMdaAll();

      setdataDetail(res.map((e) => e.overHeadReport));

      // this.setState({
      //     allBudgetCodification: response,
      //     loader: false,
      // });
    } else {
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response,
      //     loader: false,
      // });
    }
  };
  const getOverallAdminDetailsByMdaId = async (id) => {
    setcoloredBg("fourth");

    setshowDataSetail(true);
    setviewMdaId(false);
    // axios
    //   .get(`${BASE_API_URLs}v1/Mda`)
    setisDetailed("second");
    // alert("second");

    if (id) {
      const res = await get(
      `/Reports/GetOverallAdminReportDetailSummaryByMdaId?mdaId=${
          id || mdaId
        }&budgetPeriodId=${budgetPeriodId}`,
        // auth: true,
        // body: payload,
      );
      if (res ) {
        console.log(res);
        // setdataDetailMda
        setdataDetail([]);
        setdataDetailOriginal([]);
        setdataDetailMdaAll(res);
        console.log(res);
        setdataDetailMda(res?.overHeadReport);

        // this.setState({
        //     allBudgetCodification: response,
        //     loader: false,
        // });
      } else {
        setdataDetail([]);
        setdataDetailMdaAll([]);

        // this.setState({
        //     message: nullcheck(err.response) == "" ? "server error" : err.response,
        //     loader: false,
        // });
      }
    } else {
      alert("select MDA");
      setdataDetail([]);
      setdataDetailMdaAll([]);
    }
  };

  const getOverallLineItemDetailsByMdaId = async (id) => {
    // setcoloredBg("fifth");

    // setshowDataSetail(true);
    // setviewMdaId(false);
    // // axios
    // //   .get(`${BASE_API_URLs}v1/Mda`)
    // setisDetailed("second");
    // // alert("second");

    // if (id) {
    //   const res = await get(`/Reports/GetOverallAdminReportDetailsByMdaId?mdaId=${id || mdaId}&budgetPeriodId=${budgetPeriodId}`,
    //     // auth: true,
    //     // body: payload,
    //   );
    //   if (res ) {
    //     console.log(res);
    //     // setdataDetailMda
    //     setdataDetail([]);
    //     setdataDetailOriginal([]);
    //     setdataDetailMdaAll(res);
    //     console.log(res);
    //     setdataDetailMda(res?.overHeadReport);

    //     // this.setState({
    //     //     allBudgetCodification: response,
    //     //     loader: false,
    //     // });
    //   } else {
    //     setdataDetail([]);
    //     setdataDetailMdaAll([]);

    //     // this.setState({
    //     //     message: nullcheck(err.response) == "" ? "server error" : err.response,
    //     //     loader: false,
    //     // });
    //   }
    // } else {
    //   alert("select MDA");
    //   setdataDetail([]);
    //   setdataDetailMdaAll([]);
    // }
  };

  const getEmployeeList = async () => {
    const res = await get( `/v1/employee?q=a`,
      // auth: true,
      // pQuery: { q: "a" },
    );
    console.log(res);

    if (res) {
      const { data } = res;
      if (res) {
        console.log(data);
        setEmployee(data);
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  // const [role, setrole] = useState([])
  // const [time, settime] = useState([])

  // setfullName(authUser.firstName + " " + authUser.lastName)
  // setrole(authUser.role)
  // settime(time)

  // fullName: "",
  // role: "",
  // time: "",
  // departmentBudgetApprovalProcess: [],
  // budgetInformationData: [],
  // budgetInformationDataLength: 0,
  // mdas: [],
  // mdaNames: [],
  // approvalProcessId: [],
  // approvalProcess: [],
  // departmentBudget: [],
  // reviewData: [],
  // commentData: [],
  // employeeData: [],
  // userolesData: [],
  // staffId: "",
  // message: "",
  // loader: "",
  // employeeId: 0,
  // mdaNo: 0,
  // submittedBudget: 0,
  // reviewedBudget: 0,
  // returnedBudget: 0,
  // budgetUnderReview: 0,
  // budgetPeriod:'',

  // useEffect(() => {
  //   // getAuthorisedUser();
  //   // getDashboardStarts();
  //   // getDepartmentBudgetApprovalProcess();
  //   // getBudgetInformation();
  //   // getComment();
  //   // getEmployees();
  //   // getUserRoles();
  //   // getReviews();
  //   // getCurrentYear();
  // }, []);

  const [current, setCurrent] = useState(3);
  const onChange = (page) => {
    getOverallAdminDetailsByAll(page);
    // setCurrent(page);
  };

  return (
    <div className="w-100">
      {/* <div className="overlay">
          <div className="calendar" ref={wrapperRef}>
            <Calendar
              onChange={handleCalendarChange}
              value={calendar}
              className="calendar"
              selectRange
            />
          </div>
        </div> */}

      <div className="w-100 flex space-between flex-v-baseline">
        <Greeting />
        <LocationDetails />
      </div>

      <div className="flex " style={{ gap: 10 }}>
        <div
          onClick={() => {
            getBudgetVsActualByAll();
            sethideMdaSelect(false);
          }}
          class="page-6-card border w-100 pointer p-10 text-bold bold"
          style={{
            backgroundColor: coloredBg == "first" ? "green" : "white",
            color: coloredBg == "first" ? "white" : "black",
            margin: "0px 2.5px;",
          }}
        >
          <div style={{ borderBottom: "3px solid green" }}>
            <div
              style={{
                height: "130px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                class="flex-between "
                style={{ padding: "10px 15px; margin-bottom: 3px;" }}
              >
                <h3
                  style={{
                    color: coloredBg == "first" ? "white" : "black",
                  }}
                >
                  Overall Budget VS Actual
                </h3>
              </div>
              {/* <h2 style={{ padding: "0px 15px", fontFamily: "system-ui" }}>
                <b>{data?.length || 0}</b>
              </h2> */}
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            getBudgetVsActualByMdas(mdaId);
            sethideMdaSelect(true);
          }}
          class="page-6-card border w-100 pointer p-10"
          style={{
            backgroundColor: coloredBg == "second" ? "green" : "white",
            color: coloredBg == "second" ? "white" : "black",
            margin: "0px 2.5px;",
          }}
        >
          <div>
            <div
              style={{
                height: "130px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                class="flex-between "
                style={{ padding: "10px 15px; margin-bottom: 3px;" }}
              >
                <h3
                  style={{
                    color: coloredBg == "second" ? "white" : "black",
                  }}
                >
                  Overall Budget VS Actual By MDA
                </h3>
              </div>
              {/* <h2 style={{ padding: "0px 15px", fontFamily: "system-ui" }}>
                <b>{acceptedBudgetEnvelopeArray?.length || 0}</b>
              </h2> */}
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            getOverallAdminDetailsByAll(1);
            sethideMdaSelect(false);
          }}
          class="page-6-card border w-100 pointer p-10"
          style={{
            backgroundColor: coloredBg == "third" ? "green" : "white",
            color: coloredBg == "third" ? "white" : "black",
            margin: "0px 2.5px;",
          }}
        >
          <div style={{ borderBottom: "3px solid green" }}>
            <div
              style={{
                height: "130px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                class="flex-between "
                style={{ padding: "10px 15px; margin-bottom: 3px;" }}
              >
                <h3
                  style={{
                    color: coloredBg == "third" ? "white" : "black",
                  }}
                >
                  Overall Admin Reports
                </h3>
              </div>
              {/* <h2 style={{ padding: "0px 15px", fontFamily: "system-ui" }}>
                <b>{acceptedAssignedToEnterDetailsArray?.length || 0}</b>
              </h2> */}
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            getOverallAdminDetailsByMdaId(mdaId);
            sethideMdaSelect(true);
          }}
          class="page-6-card border w-100 pointer p-10"
          style={{
            backgroundColor: coloredBg == "fourth" ? "green" : "white",
            color: coloredBg == "fourth" ? "white" : "black",
            margin: "0px 2.5px;",
          }}
        >
          <div style={{ borderBottom: "3px solid green" }}>
            <div
              style={{
                height: "130px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                class="flex-between "
                style={{ padding: "10px 15px; margin-bottom: 3px;" }}
              >
                <h3
                  style={{
                    color: coloredBg == "fourth" ? "white" : "black",
                  }}
                >
                  Overall Admin Reports By MDA
                </h3>
              </div>
              {/* <h2 style={{ padding: "0px 15px", fontFamily: "system-ui" }}>
                <b>{acceptedAssignedToEnterDetailsArray?.length || 0}</b>
              </h2> */}
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            getOverallLineItemDetailsByMdaId(mdaId);
            sethideMdaSelect(true);
          }}
          class="page-6-card border w-100 pointer p-10"
          style={{
            backgroundColor: coloredBg == "fifth" ? "green" : "white",
            color: coloredBg == "fourth" ? "white" : "black",
            margin: "0px 2.5px;",
          }}
        >
          <div style={{ borderBottom: "3px solid green" }}>
            <div
              style={{
                height: "130px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                class="flex-between "
                style={{ padding: "10px 15px; margin-bottom: 3px;" }}
              >
                <h3
                  style={{
                    color: coloredBg == "fifth" ? "white" : "black",
                  }}
                >
                  Line Item Reports By MDA
                </h3>
              </div>
              {/* <h2 style={{ padding: "0px 15px", fontFamily: "system-ui" }}>
                <b>{acceptedAssignedToEnterDetailsArray?.length || 0}</b>
              </h2> */}
            </div>
          </div>
        </div>
      </div>

    {  coloredBg !== "fourth" &&<div className="p-10 bold m-t-10" style={{ backgroundColor: "#d5ffd5" }}>
        <p className="no-margin">Budget Reporting</p>

        {hideMdaSelect && (
          <div className="" style={{ width: "40%" }}>
            <Select
              // mode="multiple"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              onChange={(e) => {
                setMdaId(e.value);
                isDetailed == "first"
                  ? getBudgetVsActualByMdas(e.value)
                  : isDetailed === "second"
                  ? getOverallAdminDetailsByMdaId(e.value)
                  : alert(e);
                // getApprovedMdasByID(SelectedbudgetPeriod, e?.value);
                setSelectedMdaId(e);
              }}
              labelInValue
              // disabled={!SelectedbudgetPeriod}
              allowClear
              value={SelectedMdaId}
              style={{ width: "100%", border: "" }}
              placeholder="Please search and select MDA"
            >
              {Array.isArray(listOfMdas) &&
                listOfMdas?.map((obj) => (
                  <Option
                    value={obj?.id}
                    key={obj?.id}
                  >{`${obj?.name}`}</Option>
                ))}
            </Select>
          </div>
        )}
      </div>}
      {viewMdaId && (
        <div class="flex" style={{ gap: 10 }}>
          <div
            class="page-6-card border w-100 pointer p-10"
            //    style={{
            //   backgroundColor: coloredBg == "first" ? "green" : "white",
            //   color: coloredBg == "first" ? "white" : "black",
            //   margin: "0px 2.5px;",
            // }}
          >
            <div style={{ borderBottom: "3px solid green" }}>
              <div
                style={{
                  height: "130px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  class="flex-between"
                  style={{ padding: "10px 15px; margin-bottom: 3px;" }}
                >
                  <p>Total Budget</p>
                </div>
                <h2 style={{ padding: "0px 15px", fontFamily: "system-ui" }}>
                  <b>
                    &#8358;{" "}
                    {numberWithCommas(data?.totalBudget?.toFixed(2)) || 0}
                  </b>
                </h2>
              </div>
            </div>
          </div>
          <div
            class="page-6-card border w-100 pointer p-10"
            // style={{ backgroundColor: "white", margin: "0px 2.5px;" }}
          >
            <div style={{ borderBottom: "3px solid green" }}>
              <div
                style={{
                  height: "130px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  class="flex-between "
                  style={{ padding: "10px 15px; margin-bottom: 3px;" }}
                >
                  <p>Actual Spent</p>
                </div>
                <h2 style={{ padding: "0px 15px", fontFamily: "system-ui" }}>
                  <b>
                    &#8358;{" "}
                    {numberWithCommas(data?.actualSpent?.toFixed(2)) || 0}
                  </b>
                </h2>
              </div>
            </div>
          </div>
          <div
            class="page-6-card border w-100 pointer p-10"
            // style={{ backgroundColor: "white", margin: "0px 2.5px;" }}
          >
            <div style={{ borderBottom: "3px solid green" }}>
              <div
                style={{
                  height: "130px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  class="flex-between "
                  style={{ padding: "10px 15px; margin-bottom: 3px;" }}
                >
                  <p>Percentage Utilization</p>
                </div>
                <h2 style={{ padding: "0px 15px", fontFamily: "system-ui" }}>
                  <b>{data?.percentageUtilization?.toFixed(2) || 0} %</b>
                </h2>
              </div>
            </div>
          </div>
        </div>
      )}

      {showDataSetail && coloredBg !== "fourth" && (
        <div>
          {true && (
            <div>
              <Button
                onClick={(e) => {
                  setbudgetButtonType("overhead");

                  setdataDetailMda(dataDetailMdaAll?.overHeadReport);
                  setdataDetail(
                    dataDetailOriginal.map((e) => e.overHeadReport)
                  );
                }}
                style={{
                  backgroundColor:
                    budgetButtonType == "overhead" ? "green" : "yellow",
                  color: budgetButtonType == "overhead" ? "white" : "black",
                }}
              >
                Overhead
              </Button>
              <Button
                onClick={(e) => {
                  setbudgetButtonType("capital");
                  setdataDetailMda(dataDetailMdaAll?.capitalReport);
                  setdataDetail(dataDetailOriginal.map((e) => e.capitalReport));
                }}
                style={{
                  backgroundColor:
                    budgetButtonType == "capital" ? "green" : "yellow",
                  color: budgetButtonType == "capital" ? "white" : "black",
                }}
              >
                Capital
              </Button>
              <Button
                onClick={(e) => {
                  setbudgetButtonType("personnel");
                  setdataDetailMda(dataDetailMdaAll?.personnelReport);
                  setdataDetail(
                    dataDetailOriginal.map((e) => e.personnelReport)
                  );
                }}
                style={{
                  backgroundColor:
                    budgetButtonType == "personnel" ? "green" : "yellow",
                  color: budgetButtonType == "personnel" ? "white" : "black",
                }}
              >
                Personnel
              </Button>
            </div>
          )}

          {(
            <div style={{ width: "100%", overflowX: "auto" }}>
              <ReportTableAll
                coloredBg={coloredBg}
                budgetButtonType={budgetButtonType}
                data={dataDetail}
                dataDetailMda={dataDetailMda}
              />
              {coloredBg === "third" && (
                <Pagination current={current} onChange={onChange} total={50} />
              )}
            </div>
          )}
        </div>
      )}
          {coloredBg === "fourth" && <AdminBudgetReqForReports viewOnly = {true}/>}

    </div>
  );
};

export default Dashboard;
