import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Select,
  Form,
  Input,
  InputNumber,
  Table,
  Typography,
} from "antd";

import Cookies from "js-cookie";

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// import ViewGovTable from './ViewGovTable';

// import SearchDocument from '../layouts/SearchDocument';
// const BASE_API_URLs = "https://edogoverp.com/newbudgetapi/api/v1";

// const BASE_API_URL = 'http://localhost:5000/api/v1';

// allMdaDrop: '/api/employee/mdas',

const BudgetItemsDisplay = (props) => {
  const [commentsBudget, setCommentsBudget] = useState([]);
  const [commentsBudget2, setCommentsBudget2] = useState([]);
  const [catchTotalRecurrent, setCatchTotalRecurrent] = useState(null);
  const [catchTotalPersonal, setCatchTotalPersonal] = useState(null);
  const [catchTotalOverHead, setCatchTotalOverHead] = useState(null);
  const [catchTotalCapitalExp, setCatchTotalCapitalExp] = useState(null);
  const [catchTotalCapitalReceipt, setCatchTotalCapitalReceipt] =
    useState(null);

  const [mdaId, setMdaId] = useState([]);
  const [userId, setUserId] = useState([]);

  const { Option } = Select;
  const { Text } = Typography;
  function numberWithCommas(x) {
    return (
      x
        // ?.toFixed(2)
        ?.toString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
  }
  useEffect(() => {
    console.log(props.budgetTables);
    // sumAllArrays(props.budgetTables);
    // console.log(props.budgetTables);
    console.log(props.overHeadCost);
    console.log(props.CapitalReceipts);

    if (props.budgetTables) {
      sumAllArrays(props.budgetTables);
    } else {
      setCommentsBudget([
        // props.overHeadCost,
        props.RecurrentRevenue,
        // props.PersonnelExpenditure,
        // props.CapitalExpenditure,
        props.CapitalReceipts,
      ]);
      setCommentsBudget2([
        props.overHeadCost,
        // props.RecurrentRevenue,
        props.PersonnelExpenditure,
        props.CapitalExpenditure,
        // props.CapitalReceipts,
      ]);
    }
    // setCommentsBudget([
    //   // props.overHeadCost,
    //   props.RecurrentRevenue,
    //   // props.PersonnelExpenditure,
    //   // props.CapitalExpenditure,
    //   props.CapitalReceipts,
    // ]);
    // setCommentsBudget2([
    //   props.overHeadCost,
    //   // props.RecurrentRevenue,
    //   props.PersonnelExpenditure,
    //   props.CapitalExpenditure,
    //   // props.CapitalReceipts,
    // ]);
    console.log(+Cookies.get("mdaId"));

    setMdaId(+Cookies.get("mdaId"));

    console.log(Cookies.get("signature"));
    console.log(Cookies.get("firstName"));
    console.log(Cookies.get("lastName"));
    // setsignature(Cookies.get("signature"));

    // let ui = localStorage.getItem("ui");
    // ui = decodeToken("ui").ui;
    // console.log(ui);
    // setUserId(ui);
    // console.log(props.overHeadCost);
  }, [
    props.overHeadCost,
    props.PersonnelExpenditure,
    props.PersonnelExpenditure,
    props.CapitalExpenditure,
    props.CapitalReceipts,
    props?.budgetTables,
  ]);

  useEffect(() => {
    if (props.budgetTables) {
      setCommentsBudget([
        // props.overHeadCost,
        // props.RecurrentRevenue,
        catchTotalRecurrent,
        catchTotalCapitalReceipt,
        // props.PersonnelExpenditure,
        // props.CapitalExpenditure,
        // props.CapitalReceipts,
      ]);
      setCommentsBudget2([
        catchTotalOverHead,
        catchTotalPersonal,
        catchTotalCapitalExp,
        // props.overHeadCost,
        // // props.RecurrentRevenue,
        // props.PersonnelExpenditure,
        // props.CapitalExpenditure,
        // props.CapitalReceipts,
      ]);
    }
  }, [
    catchTotalRecurrent,
    catchTotalCapitalReceipt,
    catchTotalOverHead,
    catchTotalPersonal,
    catchTotalCapitalExp,
    props?.budgetTables,
  ]);

  const sumAllArrays = (item) => {
    getTotalOverHead(item);
    getTotalRecurrent(item);
    getTotalPersonal(item);
    getTotalCapitalExp(item);
    getTotalCapitalReceipt(item);
    // console.log(val);
    // // console.log(propzApproved);
    // // propzApproved
    // val?.forEach((item, idx) => {
    //   const result = getTotalOverHead(item);
    //   // const variableName = `result${idx + 1}`;
    //   // dynamicVariablesOverhead[variableName] = result; // Store the result in the object
    // });

    // val?.forEach((item, idx) => {
    //   const result = getTotalRecurrent(item);
    //   // const variableName = `result${idx + 1}`;
    //   // dynamicVariablesRecurrent[variableName] = result; // Store the result in the object
    // });

    // val?.forEach((item, idx) => {
    //   const result = getTotalPersonal(item);
    //   // const variableName = `result${idx + 1}`;
    //   // dynamicVariablesPersonel[variableName] = result; // Store the result in the object
    // });

    // val?.forEach((item, idx) => {
    //   const result = getTotalCapitalExp(item);
    //   // const variableName = `result${idx + 1}`;
    //   // dynamicVariablesCapitalExp[variableName] = result; // Store the result in the object
    // });
    // val?.forEach((item, idx) => {
    //   const result =
    //     item?.capitalreceipts?.length > getTotalCapitalReceipt(item);
    //   // const variableName = `result${idx + 1}`;
    //   // dynamicVariablesCapitalReceipt[variableName] = result; // Store the result in the object
    // });

    // getTotalRecurrent(val);
    // getTotalPersonal(val);
    // getTotalOverHead(val);
    // getTotalCapitalExp(val);
    // val?.capitalreceipts?.length > 0 && getTotalCapitalReceipt(val);
  };

  const getTotalRecurrent = (val) => {
    let kk = {};

    let Proposed2024A = 0;
    let Approved2023A = 0;
    let ActualJanToSep2023A = 0;
    let Approved2021A = 0;
    let AvailableBudget = 0;
    val?.recurrentRevenues?.forEach(
      (a) => (
        (Proposed2024A = Proposed2024A + a?.proposedRevenue),
        (Approved2023A = Approved2023A + a?.previousApprovedRevenue),
        (ActualJanToSep2023A = ActualJanToSep2023A + a?.currentRevenueAsAt),
        (Approved2021A = Approved2021A + a?.previousGeneratedRevenue),
        (AvailableBudget = AvailableBudget + a?.availableBudget)
      )
    );

    let yy = {
      name: "Recurrent Revenue",
      Proposed2024: Proposed2024A,
      Approved2023: Approved2023A,
      ActualJanToSep2023: ActualJanToSep2023A,
      Approved2021: Approved2021A,
      AvailableBudget: AvailableBudget,
    };

    // const sum = val?.recurrentRevenues?.reduce((accumulator, current) => {
    //   console.log(accumulator.proposedRevenue);
    //   console.log(current.proposedRevenue);
    //   console.log(accumulator + current.proposedRevenue);

    //   kk = {
    //     name: "Recurrent Revenue",
    //     Proposed2024: accumulator + current.proposedRevenue,
    //     Approved2023: accumulator + current.previousApprovedRevenue,
    //     ActualJanToSep2023: accumulator + current.currentRevenueAsAt,
    //     Approved2021: accumulator + current.previousGeneratedRevenue,
    //   };
    // }, 0);
    // console.log(kk);
    setCatchTotalRecurrent(yy);

    return yy;
  };

  const getTotalPersonal = (val) => {
    console.log(val);
    //  let kk = {};

    let sumz = 0;

    let Proposed2024A = 0;
    let Approved2023A = 0;
    let ActualJanToSep2023A = 0;
    let Approved2021A = 0;
    let AvailableBudget = 0;

    val?.personnelCosts?.forEach(
      (a) => (
        (Proposed2024A = Proposed2024A + a?.proposedExpense),
        (Approved2023A = Approved2023A + a?.prevApprovedExpense),
        (ActualJanToSep2023A = ActualJanToSep2023A + a?.currentExpenseAsAt),
        (Approved2021A = Approved2021A + a?.previousExpense),
        (AvailableBudget = AvailableBudget + a?.availableBudget)
      )
    );

    let yy = {
      name: "Personel Expenditure",
      Proposed2024: Proposed2024A,
      Approved2023: Approved2023A,
      ActualJanToSep2023: ActualJanToSep2023A,
      Approved2021: Approved2021A,
      AvailableBudget: AvailableBudget,
    };

    console.log(yy);

    let kk = {};
    console.log(val?.personnelCosts);
    const sum = val?.personnelCosts?.reduce((accumulator, current) => {
      console.log(accumulator + current?.prevApprovedExpense);

      kk = {
        name: "Personel Expenditure",
        Proposed2024: accumulator + current?.proposedExpense,
        Approved2023: accumulator + current?.prevApprovedExpense,
        ActualJanToSep2023: accumulator + current?.currentExpenseAsAt,
        Approved2021: accumulator + current?.previousExpense,
      };
    }, 0);

    console.log(kk);
    setCatchTotalPersonal(yy);
    return yy;
  };

  const getTotalOverHead = (val) => {
    let Proposed2024A = 0;
    let Approved2023A = 0;
    let ActualJanToSep2023A = 0;
    let Approved2021A = 0;
    let AvailableBudget = 0;

    val?.budgetItems?.forEach(
      (a) => (
        (Proposed2024A = Proposed2024A + a?.proposedBudget),
        (Approved2023A = Approved2023A + a?.previousApprovedBudget),
        (ActualJanToSep2023A = ActualJanToSep2023A + a?.currentExpenditures),
        (Approved2021A = Approved2021A + a?.previousExpenditures),
        (AvailableBudget = AvailableBudget + a?.availableBudget)
      )
    );

    let yy = {
      name: "Overhead Expenditure",
      Proposed2024: Proposed2024A,
      Approved2023: Approved2023A,
      ActualJanToSep2023: ActualJanToSep2023A,
      Approved2021: Approved2021A,
      AvailableBudget: AvailableBudget,
    };

    // let kk = {};
    // const sum = val?.budgetItems?.reduce((accumulator, current) => {
    //   kk = {
    //     name: "Overhead Expenditure",
    //     Proposed2024: accumulator + current.proposedBudget,
    //     Approved2023: accumulator + current.previousApprovedBudget,
    //     ActualJanToSep2023: accumulator + current.currentExpenditures,
    //     Approved2021: accumulator + current.previousExpenditures,
    //   };
    // }, 0);
    setCatchTotalOverHead(yy);
    return yy;
  };

  const getTotalCapitalExp = (val) => {
    let Proposed2024A = 0;
    let Approved2023A = 0;
    let ActualJanToSep2023A = 0;
    let Approved2021A = 0;
    let AvailableBudget = 0;

    val?.capitalProjects?.forEach(
      (a) => (
        (Proposed2024A = Proposed2024A + a.proposedAppropriation),
        (Approved2023A = Approved2023A + a.currentApprovedAppropriation),
        (ActualJanToSep2023A = ActualJanToSep2023A + a.actualExpenditureAsAt),
        (Approved2021A = Approved2021A + 0),
        (AvailableBudget = AvailableBudget + a?.availableBudget)
      )
    );

    let yy = {
      name: "Capital Expenditure",
      Proposed2024: Proposed2024A,
      Approved2023: Approved2023A,
      ActualJanToSep2023: ActualJanToSep2023A,
      Approved2021: Approved2021A,
      AvailableBudget: AvailableBudget,
    };

    let kk;
    // const sum = val?.capitalProjects?.reduce((accumulator, current) => {
    //   kk = {
    //     name: "Capital Expenditure",
    //     Proposed2024: accumulator + current.proposedAppropriation,
    //     Approved2023: accumulator + current.actualExpenditureAsAt,
    //     ActualJanToSep2023: accumulator + current.currentApprovedAppropriation,
    //     Approved2021: accumulator + 0,
    //   };
    // }, 0);
    setCatchTotalCapitalExp(yy);
    return yy;
  };
  const getTotalCapitalReceipt = (val) => {
    console.log(val);

    let Proposed2024A = 0;
    let Approved2023A = 0;
    let ActualJanToSep2023A = 0;
    let Approved2021A = 0;
    let AvailableBudget = 0;

    val?.capitalReceipts?.forEach(
      (a) => (
        (Proposed2024A = Proposed2024A + a?.proposedAppropriation),
        (Approved2023A = Approved2023A + a?.currentApprovedAppropriation),
        (ActualJanToSep2023A = ActualJanToSep2023A + a?.actualExpenditureAsAt),
        (Approved2021A = Approved2021A + 0),
        (AvailableBudget = AvailableBudget + a?.availableBudget)
      )
    );

    let yy = {
      name: "Capital Receipts",
      Proposed2024: Proposed2024A,
      Approved2023: Approved2023A,
      ActualJanToSep2023: ActualJanToSep2023A,
      Approved2021: Approved2021A,
      AvailableBudget: AvailableBudget,
    };

    let kk = {};
    const sum =
      val?.capitalReciepts?.length > 0 &&
      val?.capitalReciepts?.reduce((accumulator, current) => {
        kk = {
          name: "Capital Receipts",
          Proposed2024: accumulator + current?.proposedAppropriation,
          Approved2023: accumulator + current?.currentApprovedAppropriation,
          ActualJanToSep2023: accumulator + current?.actualExpenditureAsAt,
          Approved2021: accumulator + 0,
        };
      }, 0);

    console.log(yy);
    setCatchTotalCapitalReceipt(yy);
    return yy;
  };

  console.log(commentsBudget);
  const personnelCostsColumn = [
    // {
    //   title: "Administrative Code",
    //   dataIndex: "administrativeCode",
    //   key: "name",
    //   render: (text) => <a>{text}</a>,
    // },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      //  width: "20%",
    },
    {
      title: "Approved 2022",
      dataIndex: "Approved2021",
      key: "proposedBudget",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "Approved 2023",
      dataIndex: "Approved2023",
      key: "previousApprovedBudget",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "Proposed 2024",
      dataIndex: "Proposed2024",
      key: "currentExpenditures",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "Available Budget 2024",
      dataIndex: "AvailableBudget",
      key: "availableBudget",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },

    {
      title: " Actual Jan To Sep 2023",
      dataIndex: "ActualJanToSep2023",
      key: "economicCode",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
  ];

  const personnelCostsColumnViewOnly = [
    // {
    //   title: "Administrative Code",
    //   dataIndex: "administrativeCode",
    //   key: "name",
    //   render: (text) => <a>{text}</a>,
    // },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      //  width: "20%",
    },
    // {
    //   title: "Approved 2022",
    //   dataIndex: "Approved2021",
    //   key: "proposedBudget",
    //   render: (text) => <a>{numberWithCommas(text)}</a>,

    //   //  width: "20%",
    // },
    // {
    //   title: "Approved 2023",
    //   dataIndex: "Approved2023",
    //   key: "previousApprovedBudget",
    //   render: (text) => <a>{numberWithCommas(text)}</a>,

    //   //  width: "20%",
    // },
    {
      title: "Proposed 2024",
      dataIndex: "Proposed2024",
      key: "currentExpenditures",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "Available Budget 2024",
      dataIndex: "AvailableBudget",
      key: "availableBudget",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },

    // {
    //   title: " Actual Jan To Sep 2023",
    //   dataIndex: "ActualJanToSep2023",
    //   key: "economicCode",
    //   render: (text) => <a>{numberWithCommas(text)}</a>,

    //   //  width: "20%",
    // },
  ];


  const personnelCostsData = commentsBudget?.map((e) => ({
    name: e?.name,
    ActualJanToSep2023: e?.ActualJanToSep2023,
    Approved2021: e?.Approved2021,
    Approved2023: e?.Approved2023,
    Proposed2024: e?.Proposed2024,
    AvailableBudget: e?.AvailableBudget,
  }));
  const personnelCostsData2 = commentsBudget2?.map((e) => ({
    name: e?.name,
    ActualJanToSep2023: e?.ActualJanToSep2023,
    Approved2021: e?.Approved2021,
    Approved2023: e?.Approved2023,
    Proposed2024: e?.Proposed2024,
    AvailableBudget: e?.AvailableBudget,
  }));

  // console.log(personnelCostsData);

  //   props.handleSubmitz(personnelCostsData);

  return (
    <div className="w-100">
      {/* <h2>Budget Items</h2> */}

      <div style={{ overflowX: "scroll", marginTop: "20px" }}>
        <div style={{ width: "" }}>
          <Table
            style={{ backgroundColor: "#d3d3d3" }}
            columns={props.viewOnly ?personnelCostsColumnViewOnly:personnelCostsColumn}
            dataSource={personnelCostsData2}
            summary={(pageData) => {
              let totalActualJanToSep2023 = 0;
              let totalApproved2021 = 0;
              let totalApproved2023 = 0;
              let totalProposed2024 = 0;
              let totalAvailableBudget = 0;
              let totalBorrow = 0;
              let totalRepayment = 0;
              pageData.forEach(
                ({
                  ActualJanToSep2023,
                  Approved2021,
                  Approved2023,
                  Proposed2024,
                  AvailableBudget,
                  borrow,
                  repayment,
                }) => {
                  totalActualJanToSep2023 += +ActualJanToSep2023;
                  totalApproved2021 += +Approved2021;
                  totalApproved2023 += +Approved2023;
                  totalProposed2024 += +Proposed2024;
                  totalAvailableBudget += +AvailableBudget;
                  totalBorrow += borrow;
                  totalRepayment += repayment;
                }
              );
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                   {!props.viewOnly && <Table.Summary.Cell index={1}>
                      <Text
                        className="bold"
                        type="success"
                        style={{ fontWeight: "bold" }}
                      >{`₦ ${numberWithCommas(totalApproved2021)}`}</Text>
                    </Table.Summary.Cell>}
                   {!props.viewOnly && <Table.Summary.Cell index={3}>
                      <Text
                        className="bold"
                        type="success"
                        style={{ fontWeight: "bold" }}
                      >
                        {`₦ ${numberWithCommas(totalApproved2023)}`}
                      </Text>
                    </Table.Summary.Cell>}
                    <Table.Summary.Cell index={3}>
                      <Text
                        className="bold"
                        type="success"
                        style={{ fontWeight: "bold" }}
                      >
                        {`₦ ${numberWithCommas(totalProposed2024)}`}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      <Text
                        className="bold"
                        type="success"
                        style={{ fontWeight: "bold" }}
                      >
                        {`₦ ${numberWithCommas(totalAvailableBudget)}`}
                      </Text>
                    </Table.Summary.Cell>
                   {!props.viewOnly && <Table.Summary.Cell index={4}>
                      <Text
                        className="bold"
                        type="success"
                        style={{ fontWeight: "bold" }}
                      >
                        {`₦ ${numberWithCommas(totalActualJanToSep2023)} `}
                      </Text>
                    </Table.Summary.Cell>}
                  </Table.Summary.Row>
                  {/* const {Text} = Typography; */}
                  {/* <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Balance</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={2}>
                      <Text type="primary" style={{fontWeight:'bold'}}>{totalBorrow - totalRepayment}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row> */}
                </>
              );
            }}
          />
        </div>
      </div>

      <div style={{ overflowX: "scroll" }}>
        <div style={{ width: "" }}>
          <Table
            columns={personnelCostsColumn}
            dataSource={personnelCostsData}
            summary={(pageData) => {
              let totalActualJanToSep2023 = 0;
              let totalApproved2021 = 0;
              let totalApproved2023 = 0;
              let totalProposed2024 = 0;
              let totalBorrow = 0;
              let totalRepayment = 0;
              pageData.forEach(
                ({
                  ActualJanToSep2023,
                  Approved2021,
                  Approved2023,
                  Proposed2024,
                  borrow,
                  repayment,
                }) => {
                  totalActualJanToSep2023 += +ActualJanToSep2023;
                  totalApproved2021 += +Approved2021;
                  totalApproved2023 += +Approved2023;
                  totalProposed2024 += +Proposed2024;
                  totalBorrow += borrow;
                  totalRepayment += repayment;
                }
              );
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      <Text
                        className="bold"
                        type="success"
                        style={{ fontWeight: "bold" }}
                      >{`₦ ${numberWithCommas(totalApproved2021)}`}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      <Text
                        className="bold"
                        type="success"
                        style={{ fontWeight: "bold" }}
                      >
                        {`₦ ${numberWithCommas(totalApproved2023)}`}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      <Text
                        className="bold"
                        type="success"
                        style={{ fontWeight: "bold" }}
                      >
                        {`₦ ${numberWithCommas(totalProposed2024)}`}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      <Text
                        className="bold"
                        type="success"
                        style={{ fontWeight: "bold" }}
                      >
                        {`₦ ${numberWithCommas(totalProposed2024)}`}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      <Text
                        className="bold"
                        type="success"
                        style={{ fontWeight: "bold" }}
                      >
                        {`₦ ${numberWithCommas(totalActualJanToSep2023)} `}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  {/* const {Text} = Typography; */}
                  {/* <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Balance</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={2}>
                      <Text type="primary" style={{fontWeight:'bold'}}>{totalBorrow - totalRepayment}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row> */}
                </>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BudgetItemsDisplay;
