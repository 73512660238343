import VoucherPreview from './VoucherPreview';
import SchedulePreview from './SchedulePreview';
import { IoMdClose } from 'react-icons/io';
import ChequeModal from './ChequeModal';
import ViewDetailsReleaseWarrant from './ViewDetailsReleaseWarrant';
import ViewDetailsPopup from './ViewDetailsPopup';
import { useState } from 'react';
import loadingImg from "../../assets/svg/loading.svg"
import { get } from '../../utility/fetchExpense';


const DocumentModal = ({ type, paymentVoucher, schedule, closeModal }) => {

    const [expenseDoc, setExpenseDoc] = useState({})
    const [viewExpenseModal, setViewExpenseModal] = useState(false);
    const [viewWarrantModal, setViewWarrantModal] = useState(false);
    const [expenseDetails, setExpenseDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false)


    const viewExpenseDocument = async (data) => {
        setIsLoading(true)
        console.log(data)
        setExpenseDetails(data?.all)
        let res = await get(`/api/ExpenseApprovalDetails/selectedexpenseByReference?referenceNumber=${paymentVoucher?.referenceNumber}`)
        if (res) {
            await getExpenseDetails(paymentVoucher?.referenceNumber);
            setExpenseDoc(res);
            if (data === "expense") {
                setViewExpenseModal(true);
                setIsLoading(false)

            }
            else if (data === "warrant") {
                setViewWarrantModal(true);
                setIsLoading(false)

            }

            // setIsLoading(false)
        }
        console.log(res);
    }


    const getExpenseDetails = async (id) => {


        let res = await get(`/api/ExpenseApprovalDetails/treatedExpenseById?refId=${id}`)
        if (res) {
            console.log(res)
            setExpenseDetails(res?.data[0])
        }

    }



    const closeExpenseModal = () => {
        setViewExpenseModal(false);
        setViewWarrantModal(false);
    }

    return (
        <div className="overlay ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '80%', margin: '0 auto' }} >
                <div className=" no-padding">
                    <div
                        className="m-t-10 p-t-10 flex flex-h-center"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        {
                            type === "voucher" ?
                                <div>
                                    <VoucherPreview paymentVoucher={paymentVoucher} />
                                    {paymentVoucher?.subVouchers.map((item, index) => (
                                        <VoucherPreview paymentVoucher={item} />
                                    ))}


                                </div>
                                :
                                type === "schedule" ?
                                    <SchedulePreview paymentVoucher={paymentVoucher} schedule={schedule} /> :
                                    type === "comment" ?
                                        <div className='bg-white p-40'><ChequeModal paymentVoucher={paymentVoucher} schedule={schedule} type="comment" /></div> :
                                        type === "paymentCheque" ?
                                            <div className='bg-white p-40'><ChequeModal paymentVoucher={paymentVoucher} schedule={schedule} type="paymentCheque" /></div>
                                            :
                                            <div className='bg-white p-40'>
                                                {/* <img style={{ width:'21.7cm'}} src={paymentVoucher?.approvalMemoUrl} alt="A pdf? check link below" /> */}
                                                {paymentVoucher?.approvalMemoUrls?.length > 0 && paymentVoucher?.approvalMemoUrls.map((url) => (
                                                    <div className='m-b-20'><a className='m-t-20' href={url?.path} target="_blank" rel="noopener noreferrer">{`Download ${url?.description}`}</a></div>
                                                ))

                                                }
                                                {
                                                    paymentVoucher?.approvalMemoUrls?.length === 0 && <p>No Document</p>
                                                }
                                                <div className='w-100'>
                                                    <div className='w-100 m-b-20'><button disabled={isLoading} onClick={() => viewExpenseDocument("expense")} className="btn w-100"> Download Approved Expense Memo</button></div>
                                                    {/* <div className='w-100 m-b-20'><button disabled={isLoading} onClick={() => viewExpenseDocument("warrant")} className="btn w-100"> Download Release Warrant Memo</button></div> */}
                                                </div>


                                                <div className="flex flex-h-center w-100">{isLoading && <img src={loadingImg} />} </div>
                                            </div>
                        }
                    </div>
                </div>
                {viewExpenseModal && <ViewDetailsPopup details={expenseDetails} closeModal={closeExpenseModal} expenseItems={expenseDoc?.expenseItems} supportingDoc={expenseDoc?.supportingDocs} expenseQuery={expenseDoc?.expenseQuery} approvers={expenseDoc?.approverPersons} />}
                {/* {viewWarrantModal && <ViewDetailsReleaseWarrant details={expenseDetails} closeModal={closeModal} expenseItems={expenseDoc?.expenseItems} supportingDoc={expenseDoc?.supportingDocs} expenseQuery={expenseDoc?.expenseQuery} approvers={expenseDoc?.approverPersons} />} */}


            </div>
        </div >
    )
}

export default DocumentModal;