import React  from 'react';
import { Switch, Route } from 'react-router';
import { pure } from 'recompose';

import ProtectedRoute from './ProtectedRoute';
import Dashboard from '../components/pages/Dashboard';
import Settings from '../components/pages/Settings';
import RolesSetup from '../components/pages/RolesSetup';
import EmployeeProfile from '../components/pages/EmployeeProfile';
import EmployeeDetails from '../components/pages/EmployeeDetails';
import AdminBudgetReport from '../components/pages/AdminBudgetReport';
import SupervisorOrganogram from '../components/pages/SupervisorOrganogram';
import RaiseMemo from '../components/pages/RaiseMemo';
import CardDetails from '../components/layouts/CardDetails'
import Cookies from 'js-cookie';
import Finance from '../components/pages/Finance';
import FinanceReview from '../components/pages/FinanceReview';

export default pure(() => (
  <Switch>
    <ProtectedRoute
      path="/staff-review/"
      exact
      component={Dashboard}
    />
    <ProtectedRoute
      path="/staff-review/finance-dashboard/"
      exact
      component={Finance}
    />
    <ProtectedRoute
      path="/staff-review/finance-approval/"
      exact
      component={FinanceReview}
    />
    <ProtectedRoute
      path="/staff-review/budget-performance/"
      exact
      component={AdminBudgetReport}
    />
    <ProtectedRoute
      path="/staff-review/raise-memo"
      exact
      component={RaiseMemo}
    />
    <ProtectedRoute
      path="/staff-review/settings"
      exact
      component={Settings}
    />
    <ProtectedRoute
      path="/admin-portal/roles-setup"
      exact
      component={EmployeeProfile}
    />
    <ProtectedRoute
      path="/admin-portal/employee-profile"
      exact
      component={EmployeeProfile}
    />
    <ProtectedRoute
      path="/admin-portal/employee-details"
      exact
      component={EmployeeDetails}
    />
    <ProtectedRoute
      path="/admin-portal/supervisor-organogram"
      exact
      component={SupervisorOrganogram}
    />
    <ProtectedRoute path='/staff-review/logout' component={() => { 
     window.location.href = `${Cookies.get("homeLink")}`; 
     return null;
}}/>
    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
